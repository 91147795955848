import React from 'react';
import Layout from './layout';
import {usePasswordResetPage} from '../hooks/queries';
import '../styles/pages/password-reset.scss';

const PasswordResetPage = () => {
  const {heading, body} = usePasswordResetPage();

  return (
    <Layout>
      <div className="password-reset flex justify-center">
        <div className="password-reset__content flex flex-col text-center">
          <h1 className="tablet:text-7xl text-4xl font-semibold">{heading}</h1>
          <p className="text-xl font-semibold">{body}</p>
        </div>
      </div>
    </Layout>
  );
};

export default PasswordResetPage;
