import React from 'react';
// this module is a gatsby dependency
// eslint-disable-next-line import/no-unresolved
import {Router} from '@reach/router';
import PasswordResetPage from '../components/password-reset';
import {ProtectedRoute} from '../components/common';

const Protected = () => (
  <Router>
    <ProtectedRoute
      path="protected/password-reset"
      component={PasswordResetPage}
    />
  </Router>
);

export default Protected;
